export default {
    koleso: {
        needIdentification: 'Подтвердите телефон чтобы крутить колесо'
    },
    externalHeader: {
        back: 'назад'
    },
    promoCode: {
        needPhone: 'Чтобы применить промокод, нужно указать телефон',
        needPhoneButton: 'Указать',
        confirmationRequired: 'Чтобы применить промокод, нужно подтвердить телефон',
        confirmationRequiredButton: 'Подтвердить',
        bannerText: 'Отлично! Сделайте пополнение до {until} и получите БОНУС {percent}%!',
        label: 'Промокод',
        success: 'Код успешно применен',
        button: 'Применить',
        err: {
            noSuchCode: 'Код не найден',
            alreadyApplied: 'Вы уже применяли этот код',
            codeExpired: 'Срок действия кода истек',
            onlyForNewPlayers: 'Этот код только для новых игроков',
            onlyForOldPlayers: 'Этот код только для постоянных игроков',
            incompatibleGroup: 'Данный промо не выдавался вашему аккаунту',
        }
    },
    utils: {
        copied: "Скопировано"
    },
    depositBanner: {
        label: 'Пополните баланс',
        link: 'другая сумма'
    },
    appBanner: {
        text: 'Бонусы и подарки в приложении.',
        link: 'Скачать',
    },
    appBanner2: {
        text: 'Loto Club',
        link: 'Установить',
    },
    updateBanner: {
        text: 'Обновите приложение чтобы выплаты стали еще быстрее.',
        link: 'Обновить'
    },
    header: {
        login: 'Вход',
        balance: 'Баланс',
        sign_in: 'Регистрация',
    },
    banners: {
        firstDeposit: {
            title: 'Бонус новым игрокам'
        },
        wheel: {
            title: 'Колесо фортуны'
        },
        cashback: {
            title: 'Вернём на счёт',
            friday: 'Выплаты по пятницам',
        },
        application: {
            title: "Скачай LotoClub и получай БОНУСЫ!"
        },
        telegram: {
            title: "Подпишись и лови бонусы первым"
        },
        bonusBanner: {
            title: "БОНУС<br>АКТИВИРОВАН",
            action: "ПОЛУЧИТЕ +20%"
        }

    },
    index: {
        getBonusButton: 'Получить бонус',
        winnersHeader: 'Герои дня',
        showMore: 'Показать еще',

        gamesHeaderDefault: 'Игры',
        providers: 'Провайдеры',
        searchPlaceholder: 'Поиск слота',
        favorite: 'Избранное',
        lastGames: 'Мои игры',
        lotoClub: 'Лото клуб',
        slots: 'Слоты',
        roulette: 'Рулетка',
        minigames: 'Мини игры',
        sport: 'Спорт',
        allGames: 'Все игры',

        play: "Играть",
        demo: "Демо"
    },
    menu: {
        main: 'Главная',
        promo: 'Акции',
        profile: 'Мой профиль',
        history: 'История',
        deposit: 'Пополнить',
        payout: 'Вывести',
        logout: 'Выйти',
        login: 'Войти',
        entrance: 'Вход/Регистрация',
        licence: 'Юридическая информация',
        faq: 'FAQ'
    },
    login: {
        header: 'Вход',
        phoneInput: {
            label: 'Номер телефона',
            placeholder: 'Телефон',
            required: 'Укажите номер целиком',
        },
        passwordInput: {
            label: 'Ваш пароль',
            placeholder: 'пароль',
            required: 'Укажите пароль'
        },
        forgotPasswordLink: 'Забыли пароль?',
        invalidCredentials: 'Пароль введен не верно',
        restorePasswordButton: 'Восстановить пароль',
        loginButton: 'Войти',
        registerLinkHeading: 'Нет профиля?',
        registerLink: 'Зарегистрироваться',
    },
    account: {
        title: {
            real: 'Основной',
            main: 'Основной',
            bonus: 'Бонусный',
        }
    },
    profile: {
        setPhone: {
            header: 'Укажите телефон',
            label: 'Телефон',
            button: 'Указать',
            used: 'Этот номер уже используется'
        },
        header: 'Профиль',
        confirmPhone: {
            confirmed: 'Мой телефон',
            yourConfirmedPhone: 'Номер подтвержден',
            header: 'Подтвердите номер',
            button: 'подтвердить по СМС'
        },
        passwordSection: {
            header: 'Настройки безопасности',
            button: 'Сменить пароль',
        },
        passwordForm: {
            header: 'Смена пароля',
            currentPassword: {
                label: 'Введите старый пароль',
                placeholder: 'Старый пароль',
                required: 'укажите текущий пароль',
                invalid: 'неверный текущий пароль'
            },
            newPassword: {
                label: 'Введите новый пароль',
                placeholder: 'Новый пароль',
                required: 'укажите новый пароль',
                min: 'введите минмум 6 символов',
            },
            confirmPassword: {
                label: 'Повторите пароль',
                placeholder: 'Повторите пароль',
                required: 'повторите новый пароль',
                min: 'введите минмум 6 символов',
                match: 'введенные пароли не совпадают'
            },
            button: 'Сохранить изменения',
            success: {
                title: 'Пароль успешно изменен',
                text: 'нажмите на кнопку чтобы продолжить',
                link: 'на главную'
            }
        }
    },
    history: {
        header: 'История',
        cashback: 'Кэшбэк',
        payOut: 'Вывод средств',
        payOutReturn: 'Отмена вывода',
        payIn: 'Пополнение',
        status: {
            complete: 'Успешно',
            wait: 'Ожидает',
            cancel: 'Отклонено'
        },
        showMoreLink: 'Показать еще',
        noMoreResults: 'Показаны все операции'
    },
    payIn: {
        crypto: {
            label: 'Пополнение криптовалютой',
            enterButton: 'Crypto',
            complete: 'Готово',
            completeMessage: 'Платеж будет зачислен после 2 подтверждений сети',
            notAvailable: {
                label: 'Пополнение криптовалютой временно недоступно',
                back: 'Назад'
            }
        },
        no_bonus: 'Отказаться от бонуса',
        header: 'Пополнить',
        sum: {
            label: 'Введите сумму пополнения',
        },
        button: 'Пополнить с карты',
        payPhone: 'С телефона',
        phone: {
            header: 'Пополнить с телефона',
            getCode: 'Получить код',
            phoneLabel: 'Телефон',
            codeLabel: 'Код подтверждения',
            pay: 'Оплатить',
            invalidCode: 'Неверный код',
            beelineNote: 'Внимание: сервис доступен для абонентов Теле2, Актив, Кселл, Алтел. Для пополнения через оператора Билайн используйте кнопку "С телефона 2."',
            onlyBeelineNote: 'Внимание: сервис доступен для абонентов Билайн.'
        },
        error: {
            title: 'Ошибка!',
            reasons: 'Возможные причины:',
            funds: 'на карте нет денег',
            online_blocked: 'закрыта онлайн оплата',
            online_limit: 'исчерпан лимит на онлайн платежи',
            link: 'Повторить платеж'
        },
        success: {
            title: 'Пополнено!',
            text: 'Ваш баланс успешно пополнен',
            link: 'На главную'
        },
        pending: {
            title: 'Ожидайте',
            text: 'Ваш баланс скоро будет пополнен',
            link: 'На главную'
        },
        kaspi: {
            header: "Реквизиты для Kaspi перевода",
            footer: "После оплаты напишите в Чат. Время зачисления от 5 до 30 минут.",
            link: "Перевод клиенту Kaspi"
        }
    },
    payOut: {
        needSetPhone: 'Для вывода нужно указать телефон',
        confirmation: {
            disabled: 'Подтверждение вывода отключено',
            enable: 'включить',
            enabled: 'Подтверждение вывода включено',
            disable: 'выключить',
            sureEnable: 'Включить СМС подтверждение?'
        },
        header: 'Вывести',
        sum: {
            label: 'Введите сумму вывода',
            positive: 'укажите положительную сумму',
            min: 'укажите сумму от {sum}',
            required: 'укажите сумму',
            max: 'сумма слишком велика'
        },
        cardNumber: {
            label: 'Введите номер карты',
            full: 'укажите номер карты полностью',
            required: 'укажите номер карты'
        },
        trc20: {
            label: 'Введите адрес USDT TRC20',
            full: 'укажите адрес полностью',
            required: 'укажите адрес'
        },
        buttonCard: 'На карту',
        buttonPhone: 'На телефон',
        buttonPay: 'Вывести',
        goBack: 'Назад',
        goHome: 'На главную',
        wagerNotice: {
            header: 'Активный вейджер',
            notice: 'Пожалуйста, сделайте ставки на сумму {sum} с основного счета, чтобы разблокировать вывод.'
        },
        insufficientFunds: {
            header: 'Недостаточно средств',
            notice: 'Вывод можно делать только с основного счета. Минимальная сумма для вывода {min}, а на вашем счету {available}.'
        },
        identificationNotice: {
            header: 'Аккаунт не идентифицирован',
            notice: 'Пожалуйста, подтвердите ваш телефон',
            button: 'Подтвердить по СМС'
        },
        error: {
            title: 'Произошла ошибка',
            link: 'Назад',
            code: {
                internal: 'внутренняя ошибка',
                no_suitable_account: 'нет подходящего аккаунта',
                funds: 'недостаточно средств',
                invalid_card: 'не подходящий номер карты',
                wager: 'у вас не отыгран вейджер',
            },
            unknownError: 'неизвестная ошибка: {code}'
        },
        success: {
            title: 'Запрос отравлен',
            text: 'Запрос на выплату успешно отправлен',
            link: 'На главную'
        }
    },
    licence: {
        header: 'Лицензия',
        agreement: 'Договор публичной оферты',
        policy: 'Политика конфиденциальности',
        info: 'Какую информацию мы собираем и как мы её используем',
        player: 'Обновление информации об игроке'
    },
    faq: {
        header: 'FAQ',
        "register": {
            "q": "Регистрация и Вход",
            "a": "Нажмите кнопку “Регистрация” в правом верхнем углу, введите номер телефона и пароль если у вас уже есть кабинет. Для открытия кабинета нужно пройти регистрацию и это не займет много времени или войдите через одну из представленных социальных сетей. Теперь Вы можете играть, выигрывать и получать бонусы!"
        },
        "who-can": {
            "q": "Кто может открыть кабинет?",
            "a": "Кабинет могут открыть только лица старше 18 лет или достигшие допустимого возраста в соответствии с законодательством страны, в которой находится указанное лицо. У каждого игрока может быть только один кабинет"
        },
        "find-game": {
            "q": "Как найти игру?",
            "a": "Используя «Поиск» на главной странице введите название игры полностью или несколько первых букв. Так же игры можно фильтровать по «Провайдеру»."
        },
        "deposit": {
            "q": "Как пополнить?",
            "a": "Нажмите «Пополнить» и укажите сумму пополнения. В открывшемся окне введите номер карты, срок действия и трёхзначный код с обратной стороны карты. После этого нажмите «Оплатить». Пополнение происходит моментально за исключением редких случаев по вине банка. По любым вопросам обращайтесь в чат."
        },
        "payout": {
            "q": "Как получить?",
            "a": "Нажмите «Вывести» и в открывшемся окне укажите сумму и номер карты, на которую хотите получить выплату. После проверки оплата поступит на карту. По любым вопросам обращайтесь в чат."
        },
        "min-sum": {
            "q": "Какая минимальная сумма пополнения и выплаты?",
            "a": "Минимальная сумма пополнения 1000 KZT. Минимальная сумма выплаты 1000 KZT."
        },
        "bonus": {
            "q": "Что такое Бонусы и Бонусный счет?",
            "a": "Бонусы - это внутренняя валюта клуба, которая начисляется в качестве подарка от нашего заведения и хранятся на Бонусном счете. Бонусы можно получать участвуя в акциях. Для вывода бонусных средств, сумму бонуса необходимо отыграть."
        },
        "wager": {
            "q": "Что такое Активный вейджер?",
            "a": "Вейджер — набор условий, который необходимо соблюдать для вывода полученного приза. «Отыграть» — совершить прокрутки или ставки на определенную сумму в процессе игры, независимо от выигрыша или проигрыша (считается каждое вращение барабанов, при котором списываются реальные средства). Каждый бонус и каждое пополнение счета имеет свой вейджер (коэффициент отыгрыша). Это коэфицент на которое умножается сумма для получения суммы ставок для отыгрыша. Например, вейджер х2. Чтобы вывести бонус размером 1 000, в процессе игры нужно сделать ставок на 2 000. Счетчик суммы необходимого отыгрыша находится в меню «Вывести»."
        },
        "password": {
            "q": "Забыл пароль?",
            "a": "Для восстановления пароля используйте «Забыли пароль». Введите номер телефона, введите полученный SMS код и придумайте новый пароль. По любым вопросам Вы можете писать в чат."
        }
    },
    notFound: {
        title: 'Страница не найдена',
        link: 'На главную'
    },
    logout: {
        title: 'Вы вышли из своего аккаунта',
        text: 'нажмите на кнопку чтобы продолжить',
        link: 'на главную',
    },
    smsForm: {
        title: 'Введите код из СМС',
        sent: 'Мы отправили СМС на номер',
        notDeliveredLink: 'Не пришёл код?',
        invalidCode: 'Неверный код!',
        codeExpired: 'Отправленный код больше недействителен',
        resend: 'Отправить новый'
    },
    registration: {
        unconfirmed: {
            //text: 'Введите номер телефона, вам потребуется подтвердить его для вывода средств',
            text: 'Введите номер телефона, он понадобится для восстановления пароля',
            button: 'Зарегистрироваться',
            password: {
                label: 'Придумайте пароль',
                placeholder: 'Придумайте пароль',
                error: 'Введите минимум 6 символов',
            },
            loginHint: {
                label: 'или',
                link: 'войти'
            },
            agreement: {
                error: 'Необходимо согласие с условиями',
                label: 'Я читал и согласен с условиями'
            }
        },
        init: {
            title: 'Регистрация',
            text: 'Введите номер телефона на который мы отправим СМС с кодом подтверждения',
            tel: {
                placeholder: 'Телефон',
                required: 'Укажите номер целиком',
            },
            error: {
                used: 'Этот номер телефон уже используется!',
                recoverButton: 'Восстановить пароль'
            },
            button: 'Далее',
            hasAccount: 'Уже есть аккаунт?',
            loginLink: 'Войти',
        },
        complete: {
            title: 'Придумайте пароль',
            text: 'Пароль должен содержать не менее 6 символов!',
            password: {
                label: 'Придумайте пароль',
                placeholder: 'Придумайте пароль',
            },
            confirmPassword: {
                label: 'Повторите пароль',
                placeholder: 'Пароль',
            },
            error: {
                required: 'Пожалуйста, укажите пароль',
                min: 'Слишком короткий пароль, введите минимум 6 символов',
                match: 'Пароли не совпадают',
            },
            button: 'Далее'
        },
        completed: {
            title: 'Регистрация завершена',
            text: 'вы успешно зарегистрировались, нажмите на кнопку чтобы продолжить',
            link: 'на главную'
        }
    },
    recover: {
        request: {
            header: 'Восстановление пароля',
            text: 'Введите номер телефона на который мы отправим СМС с кодом подтверждения',
            tel: {
                placeholder: 'Телефон'
            },
            error: {
                notFound: 'Номер не найден',
                regButton: 'Зарегистрироваться'
            },
            button: 'Далее'
        },
        reset: {
            header: 'Придумайте новый пароль',
            password: {
                label: 'Придумайте пароль',
                placeholder: 'Придумайте пароль',
            },
            confirmPassword: {
                label: 'Повторите пароль',
                placeholder: 'Пароль',
            },
            error: {
                required: 'Пожалуйста, укажите пароль',
                min: 'Слишком короткий пароль, введите минимум 6 символов',
                match: 'Пароли не совпадают',
            },
            button: 'Далее'
        },
        completed: {
            title: 'Пароль успешно изменен',
            text: 'нажмите на кнопку чтобы продолжить',
            link: 'на главную'
        }
    },
    social: {
        header: 'Вход через соцсети',
        wait: 'Ожидайте...',
        login_error: 'ошибка входа',
        login_cancelled: 'вход отменен',
        google: 'Вход через Google',
        mailru: 'Вход через Mail.Ru',
    },
    confirmPhone: {
        success: {
            title: 'Телефон успешно подтвержден',
            text: 'нажмите на кнопку чтобы продолжить',
            link: 'далее'
        }
    },
    "promo": {
        "take-bonus": 'Получить БОНУС',
        "cashback": {
            "title": "Клубный кэшбек",
            "body": "<p>Кэшбэк &mdash; возврат определенного процента от проигранных средств в слот играх. Тиражные игры и игры с диллерами не получают кешбек.</p>" +
                "<p>Кэшбэк доступен постоянным игрокам Клуба и равен 10% от проигрыша за неделю.</p>" +
                "<p>При расчете суммы учитываются только проигранные собственные средства с реального баланса.</p>" +
                "<p>Бонусный баланс в расчете не участвует.</p>" +
                "<p>Начисление Кешбека происходит автоматически каждую пятницу в 18 часов.</p>"
        },
        "deposit": {
            "title": "БОНУС 20% на первый депозит",
            "body": "<p>В рамках данного предложения пользователь получает бонус &mdash; 20% от суммы внесенного депозита. Минимальная  сумма депозита, необходимая для получения бонуса – 700 KZT.</p><p>В случае применения бонуса, сумма вейджера будет установленна на совокупное значение внесенных средств и  бонуса.</p><p>То есть, при внесении 10 000 тенге, вы получите 2 000 бонусов.</p>"
        },
        wheel: {
            title: "Колесо фортуны — раскрути фортуну",
            body: "<p>Колесо Фортуны — мини-игра, в которой можно выиграть бонусные баллы, прибавку к пополнению депозита или бесплатные спины от Лото Клуба. Колесо Фортуны разделено на сектора и почти в каждом из них находится свой приз. Результат игры определяется при помощи генератора случайных чисел. </p>" +
                "<br><p>Для активации колеса - пополните счет на 25 000 тенге одним платежом. Прокрутка колеса является подарочным бонусом и она бесплатна. На каждое пополнение доступна одна прокрутка. Каждое новое пополнение счета на 25 000 тенге снова активирует колесо удачи. Прокрутки не суммируются.</p>" +
                "<br><p>Раскрути ФОРТУНУ на ДЖЕКПОТ!</p>"
        },
        ggg: {
            click_to_activate: "Нажми чтобы участвовать",
            activated: "Пополни чтобы получить",
            complete: "Бонус получен!",
            title: "БОНУС 20% от GGG",
            body: "<p>Бонус от Гены Головкина игрокам ЛотоКлуба!</p>\n" +
                "<p>Каждый пользователь может получить бонус + 20% от суммы внесенного депозита. Минимальная сумма депозита, необходимая для получения бонуса от 10 000 KZT до 500 000 KZT.</p>\n" +
                "<p>В случае применения бонуса, сумма вейджера будет установленна на суммарное значение внесенных средств и бонуса.</p>\n" +
                "<p>При внесении 10 000 тенге, вы получите 2 000 бонусов.</p>\n" +
                "<p>Бонус является одноразовым и активен до 19 сентября 22:00! Верим в Гену! Алга Казахтан!</p>"
        }
    }
}
