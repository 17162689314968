<template>
  <section class="games">
    <div class="games__container">
      <FilterForm
        :search="search"
        :provider="provider"
        :category="category"
        :set-provider="setProvider"
        :set-category="setCategory"
        :providers-list="filter.providers()"
        :set-search="setSearch"
      />
      <div v-if="isFilterDisabled" class="games__all">
        <div class="games__title">{{ $t("index.lotoClub") }}</div>
        <div class="games__list">
          <GameItem
            v-for="game in lotoclubGames"
            :key="game.id"
            :game="game"
            :favorite-games="favoriteGames"
            :on-click="saveFilter"
            :on-toggle-favorite="toggleFavorite"
          />
        </div>
        <div class="games__list-control">
          <button
            class="games__show-all"
            :class="{ 'games__show-all-margin': category !== 'all' }"
            @click="allCategory('lotoclub')"
          >
            {{ $t("index.allGames") }}
          </button>
        </div>
        <div class="games__title">
          {{ $t("index.slots") }}
        </div>
        <div class="games__list">
          <GameItem
            v-for="game in gameSlots"
            :key="game.id"
            :game="game"
            :favorite-games="favoriteGames"
            :on-click="saveFilter"
            :on-toggle-favorite="toggleFavorite"
          />
        </div>
        <div class="games__list-control">
          <button
            class="games__show-all"
            :class="{ 'games__show-all-margin': category !== 'all' }"
            @click="allCategory('slots')"
          >
            {{ $t("index.allGames") }}
          </button>
        </div>

        <div class="games__title">
          {{ $t("index.roulette") }}
        </div>
        <div class="games__list">
          <GameItem
            v-for="game in gameRoulette"
            :key="game.id"
            :game="game"
            :favorite-games="favoriteGames"
            :on-click="saveFilter"
            :on-toggle-favorite="toggleFavorite"
          />
        </div>
        <div class="games__list-control">
          <button
            class="games__show-all"
            :class="{ 'games__show-all-margin': category !== 'all' }"
            @click="allCategory('roulette')"
          >
            {{ $t("index.allGames") }}
          </button>
        </div>

        <div class="games__title">
          {{ $t("index.minigames") }}
        </div>
        <div class="games__list">
          <GameItem
            v-for="game in gameLive"
            :key="game.id"
            :game="game"
            :favorite-games="favoriteGames"
            :on-click="saveFilter"
            :on-toggle-favorite="toggleFavorite"
          />
        </div>
        <div class="games__list-control">
          <button
            class="games__show-all"
            :class="{ 'games__show-all-margin': category !== 'all' }"
            @click="allCategory('minigames')"
          >
            {{ $t("index.allGames") }}
          </button>
        </div>

        <div class="games__title">
          {{ $t("index.sport") }}
        </div>
        <div class="games__list">
          <GameItem
            v-for="game in gameSport"
            :key="game.id"
            :game="game"
            :favorite-games="favoriteGames"
            :on-click="saveFilter"
            :on-toggle-favorite="toggleFavorite"
            full-width="true"
          />
        </div>
      </div>

      <div v-else class="games__all">
        <template v-if="category === 'favorite'">
          <template v-if="Object.keys(lastGames).length">
            <div class="games__title">{{ $t("index.lastGames") }}</div>
            <div class="games__list">
              <GameItem
                v-for="game in lastGames"
                :key="game.id"
                :game="game"
                :favorite-games="favoriteGames"
                :on-click="saveFilter"
                :on-toggle-favorite="toggleFavorite"
              />
            </div>
          </template>

          <div class="games__title">{{ $t("index.favorite") }}</div>
          <div class="games__list">
            <GameItem
              v-for="game in favoriteGames"
              :key="game.id"
              :game="game"
              :favorite-games="favoriteGames"
              :on-click="saveFilter"
              :on-toggle-favorite="toggleFavorite"
            />
          </div>
        </template>
        <template v-else>
          <p class="games__title">{{ filterHeading }}</p>
          <div class="games__list">
            <GameItem
              v-for="game in filteredGames()"
              :key="game.id"
              :game="game"
              :favorite-games="favoriteGames"
              :on-click="saveFilter"
              :on-toggle-favorite="toggleFavorite"
            />
          </div>
        </template>
        <div v-if="isNoMoreGames" class="games__list-control">
          <button
            class="games__show-all"
            :class="{ 'games__show-all-margin': category !== 'all' }"
            @click="allGamesNoFilter"
          >
            {{ $t("index.allGames") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GameItem from "./GameItem";
import { Filter, Game } from "./GamesFilter";
import FilterForm from "./FilterForm";
import { titleDict, providerTitle } from "./gamesConfig";

function Cache() {
  const key = "filter";
  this.set = data => localStorage.setItem(key, JSON.stringify(data));
  this.get = () => JSON.parse(localStorage.getItem(key));
  this.has = () => Boolean(localStorage.getItem(key));
  this.reset = () => localStorage.removeItem(key);
}

const cache = new Cache();

export default {
  name: "Games",
  components: { FilterForm, GameItem },
  props: ["games"],
  destroyed() {
    this.removeScrollListener();
  },
  data() {
    return {
      // filter: new Filter([]),
      category: "all",
      provider: "all",
      search: "",
      hasScrollListener: false,
      gamesLimit: 10,
      noMoreGames: false,
      titleDict,
      providerTitle,
      favoriteGames: {},
      lastGames: {}
    };
  },
  mounted() {
    if (cache.has()) {
      this.setFilter(cache.get());
      cache.reset();
    }

    this.getFavoriteGames();
    this.getLastGames();

    setTimeout(() => this.applyScroll(), 200);
  },
  methods: {
    toggleFavorite(game) {
      let favoriteGames = localStorage.getItem("favoriteGames");
      favoriteGames = favoriteGames ? JSON.parse(favoriteGames) : {};

      if (!favoriteGames[game.id]) {
        favoriteGames[game.id] = game;
      } else {
        delete favoriteGames[game.id];
      }

      localStorage.setItem("favoriteGames", JSON.stringify(favoriteGames));
      this.getFavoriteGames();
    },
    applyScroll() {
      const oldScroll = parseInt(localStorage.getItem("scroll") || 0);
      if (oldScroll) {
        window.scrollTo(0, oldScroll);
      }
      localStorage.removeItem("scroll");
    },
    saveFilter() {
      cache.set({
        category: this.category,
        provider: this.provider,
        search: this.search,
        gamesLimit: this.gamesLimit
      });

      localStorage.setItem("scroll", document.documentElement.scrollTop);
    },
    allCategory(category) {
      const scrollOver = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollTop = document.documentElement.scrollTop;

      const timeOut = (scrollTop / scrollOver) * 850;

      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => this.setCategory(category), timeOut);
    },
    allGamesNoFilter() {
      const scrollOver = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollTop = document.documentElement.scrollTop;

      const timeOut = (scrollTop / scrollOver) * 850;

      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        this.setFilter({});
        this.noMoreGames = false;
      }, timeOut);
    },

    setSearch(search) {
      this.setFilter({ search });
    },

    setProvider(provider) {
      this.setFilter({ provider: provider === this.provider ? "all" : provider });
    },

    setCategory(category) {
      this.setFilter({ category: category === this.category ? "all" : category });
    },

    filteredGames() {
      return this.filter.filter({
        category: this.category,
        provider: this.provider,
        search: this.search,
        limit: this.gamesLimit
      });
    },

    showMore() {
      this.setFilter({
        category: this.category,
        provider: this.provider,
        search: this.search,
        gamesLimit: this.gamesLimit + 10
      });
      return (
        this.gamesLimit >=
        this.filter.total_filter({
          category: this.category,
          provider: this.provider,
          search: this.search
        })
      );
    },

    clear() {
      return { category: "all", provider: "all", search: "", gamesLimit: 10 };
    },

    setFilter(merge) {
      const newFilter = { ...this.clear(), ...merge };

      const { category, provider, search, gamesLimit } = newFilter;
      this.$emit("setCategory", category);
      this.category = category;
      this.provider = provider;
      this.search = search;
      this.gamesLimit = gamesLimit;

      if (this.isFilterDisabled) {
        this.$store.commit("setDisplayWinners", true);
        this.removeScrollListener();
      } else {
        this.$store.commit("setDisplayWinners", false);
        this.addScrollListener();
      }
      this.$store.commit("setCategoryType", category);
    },

    removeScrollListener() {
      window.removeEventListener("scroll", this.scrollListener);
      this.hasScrollListener = false;
    },

    addScrollListener() {
      if (!this.hasScrollListener) {
        window.addEventListener("scroll", this.scrollListener);
        this.hasScrollListener = true;
      }
    },

    scrollListener() {
      let scrollOver = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      let scrollTop = document.documentElement.scrollTop;

      if (scrollOver < scrollTop + 60) {
        this.noMoreGames = this.showMore();
      }
    },
    getFavoriteGames() {
      const favoriteGames = localStorage.getItem("favoriteGames");
      this.favoriteGames = favoriteGames ? JSON.parse(favoriteGames) : {};
    },
    getLastGames() {
      const lastGames = localStorage.getItem("lastGames");
      this.lastGames = lastGames ? JSON.parse(lastGames) : {};
    }
  },

  computed: {
    filter() {
      return new Filter(this.games.map(g => new Game(g)));
    },
    isFilterDisabled() {
      return this.category === "all" && this.provider === "all" && this.search === "";
    },
    isNoMoreGames() {
      return this.noMoreGames;
    },

    filterHeading() {
      if (this.category !== "all") {
        return this.$t(this.titleDict[this.category]);
      }

      if (this.provider !== "all") {
        return this.providerTitle(this.provider);
      }

      if (this.search !== "") {
        return this.search;
      }

      return this.$t("index.gamesHeaderDefault");
    },

    lotoclubGames() {
      return this.filter.gamesOfCategory("lotoclub", 8);
    },

    gameSlots() {
      return this.filter.gamesOfCategory("slots", 8);
    },

    gameRoulette() {
      return this.filter.gamesOfCategory("roulette", 6);
    },

    gameLive() {
      return this.filter.gamesOfCategory("minigames", 6);
    },

    gameSport() {
      return this.filter.gamesOfCategory("sport", 6);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "games";
</style>
