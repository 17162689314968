export default (router, store) => router.beforeEach((to, from, next) => {
    // Добавляем title для SEO
    if (to.meta.title) {
        document.title = to.meta.title
    }

    // Добавляем description для SEO
    if (to.meta.description) {
        const metaDescription = document.querySelector('meta[name="description"]')

        if (metaDescription) {
            metaDescription.setAttribute('content', to.meta.description)
        } else {
            const meta = document.createElement('meta')
            meta.setAttribute('name', 'description')
            meta.setAttribute('content', to.meta.description)
            document.head.appendChild(meta)
        }
    }

    // Добавляем canonical для SEO
    const linkCanonical = document.querySelector('link[rel="canonical"]')

    if (linkCanonical) {
        linkCanonical.setAttribute('href', location.href)
    } else {
        const link = document.createElement('link')
        link.setAttribute('rel', 'canonical')
        link.setAttribute('href', location.href)
        document.head.appendChild(link)
    }

    if (
        to.matched.some(record => record.meta.requiresAuth) &&
        store.state.auth === false
    ) {
        next({name: 'login', params: {return: to.fullPath}});
        return
    }

    next()
})
